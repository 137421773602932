<template>
  <div class="rating"
       v-click-outside="closeSetter">
    <component :is="params.component || 'Row'"
               :align="params.align || 'center'"
               :justify="params.justify || ''"
               class="rating__wrapper">
      <Icon :class="rating ? 'rating__icon-checked' : ''"
            class="rating__icon"
            v-on:click.prevent.stop.native="toggleSetter"
            viewport="0 0 20 20"
            xlink="star"/>
      <span class="rating__number">{{currentUserRating}}/{{rating}}</span>
    </component>
    <Row v-if="show"
         class="rating__set-rating">
      <Icon :key="star"
            class="rating__set-rating-icon"
            v-for="star in 5"
            v-on:click.prevent.stop.native="saveRatingToServer(star)"
            viewport="0 0 20 20"
            xlink="star"/>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    params: {
      required: true,
    },
  },
  data() {
    return {
      rating: 0,
      currentUserRating: 0,
      show: false,
    };
  },
  created() {
    this.rating = this.params.rating ? this.params.rating : 0;
    this.currentUserRating = this.params.currentUserRating ? this.params.currentUserRating : 0;
  },
  methods: {
    closeSetter() {
      this.show = false;
    },
    toggleSetter(e) {
      e.preventDefault();
      if (this.$user.logged) {
        this.show = !this.show;
      } else {
        this.$redirectIfGuest();
      }
      e.stopPropagation();
    },
    saveRatingToServer(value) {
      this.closeSetter();
      this.currentUserRating = value;
      this.$http.post(`/api/${this.params.type}/${this.params.id}/ratings?value=${value}`)
        .then((resolve) => {
          this.rating = resolve.data.data.rating;
        });
    },
  },
};
</script>
